import $ from 'jquery';


import './../../scss/blocks/block-faq.scss';

$(document).ready(function() {
   
    
  $('.block-faq-section-wrap .faq-item').on('click',function(e) {
    $(this).toggleClass('active')
    $(this).find('.faq-content-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })
    

});